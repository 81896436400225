<template>
  <q-card style="width: 1200px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        编辑项目
        <small class="q-ml-sm text-size-sm">
          <!-- {{ PdmBodycheckItemEntity.PdmBodycheckItemName }}({{
                            PdmBodycheckItemEntity.PdmBodycheckItemID
                        }}) -->
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm q-pr-sm">
        <!-- <q-input
                        label="机构ID" 
                       dense
                       outlined
                       class="col-2"
                        v-model="PdmBodycheckItemEntity.HospID "
                    /> -->
        <q-input
          label="项目编码"
          dense
          outlined
          readonly
          class="col-2"
          v-model="PdmBodycheckItemEntity.Item_Code"
        />
        <q-input
          label="项目名称"
          dense
          outlined
          class="col-5"
          v-model="PdmBodycheckItemEntity.Item_Name"
        />
        <q-card class="col-12" flat bordered>
          <div class="text-subtitle1 q-pl-sm">体检流程：</div>
          <q-separator inset />
          <q-editor
            style="height: 300px;"
            :dense="$q.screen.lt.md"
            v-model="PdmBodycheckItemEntity.Item_Content"
            :toolbar="toolbar"
            :fonts="fonts"
          />
        </q-card>
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useQuasar } from "quasar";
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmBodycheckItem", [
      "PdmBodycheckItemShowDlg",
      "PdmBodycheckItemEntity",
      "PdmBodycheckItemList",
    ]),
  },
  data() {
    return {
      $q: useQuasar(),
      toolbar: [
        [
          {
            label: this.$q.lang.editor.align,
            icon: this.$q.iconSet.editor.align,
            fixedLabel: true,
            list: "only-icons",
            options: ["left", "center", "right", "justify"],
          },
          {
            label: this.$q.lang.editor.align,
            icon: this.$q.iconSet.editor.align,
            fixedLabel: true,
            options: ["left", "center", "right", "justify"],
          },
        ],
        ["bold", "italic", "strike", "underline", "subscript", "superscript"],
        ["token", "hr", "link", "custom_btn"],
        ["print", "fullscreen"],
        [
          {
            label: this.$q.lang.editor.formatting,
            icon: this.$q.iconSet.editor.formatting,
            list: "no-icons",
            options: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "code"],
          },
          {
            label: this.$q.lang.editor.fontSize,
            icon: this.$q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: "no-icons",
            options: [
              "size-1",
              "size-2",
              "size-3",
              "size-4",
              "size-5",
              "size-6",
              "size-7",
            ],
          },
          {
            label: this.$q.lang.editor.defaultFont,
            icon: this.$q.iconSet.editor.font,
            fixedIcon: true,
            list: "no-icons",
            options: [
              "default_font",
              "arial",
              "arial_black",
              "comic_sans",
              "courier_new",
              "impact",
              "lucida_grande",
              "times_new_roman",
              "verdana",
            ],
          },
          "removeFormat",
        ],
        ["quote", "unordered", "ordered", "outdent", "indent"],

        ["undo", "redo"],
        ["viewsource"],
      ],
      fonts: {
        arial: "Arial",
        arial_black: "Arial Black",
        comic_sans: "Comic Sans MS",
        courier_new: "Courier New",
        impact: "Impact",
        lucida_grande: "Lucida Grande",
        times_new_roman: "Times New Roman",
        verdana: "Verdana",
      },
    };
  },
  mounted() {},
  methods: {
    ...mapActions("PdmBodycheckItem", [
      "actUpdatePdmBodycheckItem",
      "actGetPdmBodycheckItemList",
    ]),
    onOK() {
      this.actUpdatePdmBodycheckItem({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmBodycheckItemEntity,
      }).then((res) => {
        if (res.Code == 0) {
          //setTimeout(()=> {
          //this.$emit("onLoadData");
          //}, 500);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
